/** @jsx jsx */
import { Link } from "gatsby";
import { jsx } from "theme-ui";
import { gsap } from "gsap";
import { getMousePos } from "../lib/helpers";

type Props = {
    message: string;
    link: string;
    center?: boolean;
    project?: boolean;
};

let hoverPosition = { x: 0, y: 0 };

if (typeof window !== "undefined") {
    window.addEventListener("mousemove", ev => (hoverPosition = getMousePos(ev)));
}

const Button = ({ message, link, center, project }: Props) => {
    const enterButton = e => {
        const rect = e.currentTarget.getBoundingClientRect();
        const left = hoverPosition.x - rect.left;
        const top = hoverPosition.y - rect.top;
        // e.currentTarget.children[1].style.transform = `translate(${left}px, ${top}px)`

        scaleAnimation(e.currentTarget.children[1], rect.width * 2.8, rect.height * 2.8, left, top);
    };

    const leaveButton = e => {
        const rect = e.currentTarget.getBoundingClientRect();
        const left = hoverPosition.x - rect.left;
        const top = hoverPosition.y - rect.top;
        // e.currentTarget.children[1].style.transform = `translate(${left}px, ${top}px)`
        scaleAnimation(e.currentTarget.children[1], 0, 0, left, top);
    };

    const scaleAnimation = (el, width, height, left, top) => {
        gsap.to(el, {
            duration: 0,
            x: left,
            y: top
        });

        gsap.to(el, {
            duration: 0.5,
            width: width,
            height: height,
            ease: "Power3.easeOut"
        });
    };

    return (
        <div
            className="button-container"
            sx={{
                display: "flex",
                justifyContent: center ? "center" : "start",
                mt: !project ? [2, 3, 4] : 1,
                mb: project && [3]
            }}
        >
            <Link
                cursor-scale="scale-down"
                onMouseEnter={enterButton}
                onMouseLeave={leaveButton}
                to={link}
                sx={{
                    position: "relative",
                    py: 1,
                    px: [1, 1, 2],
                    textDecoration: "none",
                    textAlign: "center",
                    border: "1px solid",
                    borderColor: "border",
                    variant: "text.body.main",
                    overflow: "hidden"
                }}
            >
                <p
                    sx={{
                        m: 0,
                        position: "relative",
                        zIndex: 1,
                        transition: "500ms",
                        "::selection": { variant: "selection" }
                    }}
                >
                    {message}
                </p>
                <div
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "1px",
                        height: "1px",
                        borderRadius: "100%",
                        //Will change button hover color
                        bg: "border",
                        transform: "translate(-50%, -50%)"
                    }}
                    className="hover-effect"
                ></div>
            </Link>
        </div>
    );
};

export default Button;
