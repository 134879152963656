/** @jsx jsx */
import { jsx } from "theme-ui";
import Button from "./button";
import Figure from "./figures";

interface Side {
    title: string;
    subtitle?: string;
    link?: string;
    height?: number;
}

interface List {
    left: Array<Side>;
    right: Array<Side>;
}

type Props = {
    list: List;
    left?: boolean;
    extend?: boolean;
    showInfo?: boolean;
    showButton?: boolean;
    center?: boolean;
    isLink?: boolean;
};

const FigureGrid = ({ list, isLink, left, extend, showInfo, showButton, center }: Props) => {
    console.log(list);
    return (
        <div
            className="project-grid"
            sx={{
                display: ["block", "block", "flex"],
                mx: extend ? [0, 0, "-13%", "-10%"] : ["-10%", 0],
                mt: [3, 5, 1]
            }}
        >
            <div
                className="project-grid__left"
                sx={{ flex: 1, mt: left && [0, 0, "10vw"], pr: [0, 0, 1, 2] }}
            >
                {list.left.map((item, index) => {
                    console.log(item);
                    return (
                        <Figure
                            listItem={item}
                            center={center}
                            isLink={isLink}
                            button={
                                showButton && (
                                    <Button
                                        message="View Project"
                                        link={`/projects/${item.slug.current}`}
                                        project
                                    />
                                )
                            }
                        />
                    );
                })}
            </div>
            <div
                className="project-grid__right"
                sx={{ flex: 1, mt: !left && [0, 0, "200px"], pl: [0, 0, 1, 2] }}
            >
                {list.right.map(item => (
                    <Figure
                        listItem={item}
                        center={center}
                        isLink={isLink}
                        button={
                            showButton && (
                                <Button
                                    message="View Project"
                                    link={`/projects/${item.slug.current}`}
                                    project
                                />
                            )
                        }
                    />
                ))}
            </div>
        </div>
    );
};

export default FigureGrid;
